import { createSelector } from '@reduxjs/toolkit';

import { TopLevelCategory } from '@videoblocks/shared-components/dist/mjs/types/types';

import { ReduxState } from '../AppLoader/types';
import {
  selectAuthPermissions,
  selectIsUserOnStarterPlan,
  selectIsUserOnGrowthPlan,
  selectIsIpAuth,
  selectIsLoggedIn,
  selectUser,
  selectUserInitials,
} from '../auth/AuthSelectors';
import { isEqual } from '../common/utils';
import { NavCategory } from './UiTypes';

function sortCategoriesByTitle(catA: NavCategory, catB: NavCategory) {
  const titleA = catA.title.toLowerCase();
  const titleB = catB.title.toLowerCase();
  if (titleA < titleB) return -1;
  if (titleB > titleA) return 1;
  return 0;
}

export const selectNavMenu = (state: ReduxState) => state.ui.nav.menu;

export const selectRightSideNavMenu = createSelector(
  [
    selectNavMenu,
    selectIsUserOnStarterPlan,
    selectIsUserOnGrowthPlan,
    selectUserInitials,
    selectUser,
    selectIsIpAuth,
    selectIsLoggedIn,
    selectAuthPermissions,
  ],
  (
    {
      showFavorites,
      account,
      planCta,
      remainingExports,
      organizationName,
      shouldShowEnterpriseExperience,
      starterPlanRemainingDownloads,
    },
    isUserOnStarterPlan,
    isUserOnGrowthPlan,
    userInitials,
    user,
    isIpAuth,
    isLoggedIn,
    { storyboardEnabled }
  ) => ({
    showFavorites,
    account,
    planCta,
    remainingExports,
    organizationName,
    shouldShowEnterpriseExperience,
    starterPlanRemainingDownloads,
    isUserOnStarterPlan,
    isUserOnGrowthPlan,
    userInitials,
    user,
    isIpAuth,
    isLoggedIn,
    storyboardEnabled,
  })
);

const selectNavMenuCollections = createSelector(
  [selectNavMenu],
  (menu) => menu.collections
);

export const selectFormattedNavMenuCollections = createSelector(
  [selectNavMenuCollections],
  (collections) =>
    Object.keys(collections).reduce(
      (acc, contentClass) => {
        const collectionItems: NavCategory[] = collections[contentClass];

        return {
          ...acc,
          [contentClass]: [
            ...collectionItems.slice(0, 10),
            collectionItems[collectionItems.length - 1], // last item is 'See All'
          ],
        };
      },
      {
        video: [],
        audio: [],
        image: [],
      }
    ),
  { memoizeOptions: { equalityCheck: isEqual } }
);

export const selectNavMenuCategories = createSelector(
  [selectNavMenu],
  (menu) => menu.top_level_categories
);

export const selectNavMenuCategory =
  (menuName: string) =>
  (state: ReduxState): TopLevelCategory =>
    selectNavMenuCategories(state)?.[menuName] || {};

export const selectCollectionsByMenu =
  (menuName: string) => (state: ReduxState) => {
    const { collections = [] } = selectNavMenuCategory(menuName)(state);

    return collections;
  };

export const selectItemsByMenu = (menuName: string) => (state: ReduxState) => {
  const { items = [] } = selectNavMenuCategory(menuName)(state);

  return items;
};

export const selectNavSubcategoriesForCategory =
  (menuName: string, categoryName: string) => (state: ReduxState) => {
    const { categories } = selectNavMenuCategory(menuName)(state);
    const subCategories = categories?.[categoryName]?.subCategories || [];

    return subCategories.sort(sortCategoriesByTitle);
  };

export const selectNavSubCategoriesForUrl =
  (menuName: string, categoryUrl: string) => (state: ReduxState) => {
    const { categories } = selectNavMenuCategory(menuName)(state);
    const topLevelCategory = Object.values(categories || {}).find(
      (category) => {
        const categoryPath = category.url.split('/')[2];

        return categoryUrl === categoryPath;
      }
    );
    return topLevelCategory?.subCategories.sort(sortCategoriesByTitle) || [];
  };

export const selectNavSubCategoriesForMobile =
  (menuCategories: string[]) =>
  (state: ReduxState): Record<string, NavCategory[]> =>
    menuCategories.reduce((accum, middleMenuCategory) => {
      const { categories } = selectNavMenuCategory(middleMenuCategory)(state);
      const subCategory = {};

      if (categories) {
        Object.keys(categories).forEach((category) => {
          const { subCategories } = categories[category];
          if (subCategories.length) {
            subCategory[category] = subCategories;
          }
        });
        accum[middleMenuCategory] = subCategory;
      }

      return accum;
    }, {});
