import React from 'react';
import { twMerge } from 'tailwind-merge';

type BetaBadgeProps = {
  badgeText?: string;
  className: string;
};

export const BetaBadgeClasses =
  'text-xs text-white bg-blue-1000 rounded-lg px-2 py-1';

const BetaBadge = ({ badgeText = 'Beta', className }: BetaBadgeProps) => {
  return (
    <span className={twMerge(BetaBadgeClasses, className)}>{badgeText}</span>
  );
};

export default BetaBadge;
