import React from 'react';

import BreadcrumbsMicrofrontend from '../../../common/components/Microfrontends/BreadcrumbsMicrofrontend';

const Breadcrumbs = () => {
  return (
    <BreadcrumbsMicrofrontend
      path={__CURRENT_PATH__ + __CURRENT_SEARCH_PARAMS__}
    />
  );
};

export default Breadcrumbs;
