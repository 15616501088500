import React, { ComponentType, useEffect, useState } from 'react';

/**
 * The microfrontend convention. An object conforming to this interface is
 * placed on `window` when the microfrontend script is loaded.
 */
interface MicrofrontendModule {
  install(element: HTMLElement, initialProps?: Record<string, unknown>): void;

  uninstall(): void;
}

/**
 * How to locate the microfrontend.
 */
interface MicrofrontendConfig {
  name: string;
  windowKey: string;
}

/**
 * Tells us if we're being rendered in v8js on the server.
 */
const isSSR = () =>
  typeof window === 'undefined' || !window.document?.createElement;

/**
 * Asynchronously load a microfrontend bundle.
 * @param {string} bundleUri Full path to the js file.
 * @param {string} windowKey Window variable known to be populated by the script.
 */
const loadMicrofrontendBundle = (
  bundleUri: string,
  windowKey: string
): Promise<MicrofrontendModule> => {
  return new Promise((resolve, reject) => {
    try {
      if (window[windowKey]) {
        resolve(window[windowKey] as MicrofrontendModule);
        return;
      }

      const tag = document.createElement('script');
      const container = document.head || document.body;

      tag.type = 'text/javascript';
      tag.async = true;
      tag.src = bundleUri;

      tag.addEventListener('load', () => {
        if (window[windowKey]) {
          resolve(window[windowKey] as MicrofrontendModule);
        } else {
          reject(new Error('No global found for ' + windowKey));
        }
      });

      tag.addEventListener('error', (e) => {
        reject(e.error);
      });

      container.appendChild(tag);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * Component factory for dynamically loading and rendering a microfrontend.
 * @param host
 * @param windowKey
 * @constructor
 */
export default function Microfrontend<Props>({
  name,
  windowKey,
}: MicrofrontendConfig): ComponentType<Props> {
  // On server provide a component that emits instructions for fetching html fragments from mfe backend.
  if (isSSR()) {
    return (initialProps: Props = {} as Props) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: `<!-- MFE-SSR name:${name} request:/api/render?${Object.entries(
              initialProps
            )
              .map(
                ([key, value]) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
              )
              .join('&')} -->`,
          }}
        />
      );
    };
  }

  const host = (window as any).__MICROFRONTEND_HOSTS__?.[name];

  return (initialProps) => {
    const [microfrontend, setMicrofrontend] =
      useState<MicrofrontendModule>(null);
    const [div, setDiv] = useState<HTMLDivElement>(null);

    useEffect(() => {
      fetch(`${host}/api/bundleUri`)
        .then((response) => response.json())
        .then(({ bundleUri }) => loadMicrofrontendBundle(bundleUri, windowKey))
        .then((microfrontend) => {
          setMicrofrontend(microfrontend);
        });
    }, []);

    useEffect(() => {
      if (div && microfrontend) {
        microfrontend.install(div, initialProps);

        return () => {
          microfrontend.uninstall();
        };
      }
    }, [div, microfrontend]);

    return <div ref={setDiv} dangerouslySetInnerHTML={{ __html: '' }} />;
  };
}
