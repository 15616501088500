import RestService from '../../common/restService/RestService';
import RestServiceFactory from '../../common/restService/RestServiceFactory';

class TeamAPI {
  private _restService: RestService;
  private readonly basePath: string;

  constructor() {
    this._restService = RestServiceFactory.fromCurrentHost();
    this.basePath = this.getBasePath();
  }

  getBasePath() {
    return '/api/member/organization';
  }

  usersList = () => {
    return this._restService
      .get(`${this.basePath}/users`)
      .then((json) => json.data);
  };

  userCreate = (data) => {
    return this._restService
      .postAsJson(`${this.basePath}/users`, data)
      .then((json) => json.data);
  };

  organizationGet = () => {
    return this._restService.get(this.basePath).then((json) => json.data);
  };

  userCancel = (userId: number) => {
    const uri = `${this.basePath}/users/${userId}/deactivate`;
    return this._restService.postAsJson(uri);
  };

  userActivateInvited = (userId) => {
    const uri = `${this.basePath}/users/${userId}/activate`;
    return this._restService.postAsJson(uri);
  };

  approveRequestedUser = (userId) => {
    const uri = `${this.basePath}/users/${userId}/approve-requested`;
    return this._restService.postAsJson(uri);
  };

  rejectRequestedUser = (userId) => {
    const uri = `${this.basePath}/users/${userId}/reject-requested`;
    return this._restService.postAsJson(uri);
  };

  resendInviteEmail = (userId) => {
    const uri = `${this.basePath}/users/${userId}/reinvite`;
    return this._restService.postAsJson(uri);
  };
}

export default TeamAPI;
