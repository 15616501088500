import { HTTPMethod } from '../Constants';
import UrlFactory from './UrlFactory';

class RestService {
  private baseUrl: string;

  /**
   * @param {string} baseUrl - all requests URLs will be relative to this base URL
   */
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl.replace(/\/$/g, ''); // rtrim incoming url so we know there's no trailing / character
  }

  /**
   * @param {string} path
   * @param {object} [options]
   * @param {object} [options.urlParams]
   * @param {object} [options.queryParams]
   *
   * @returns {Promise}
   */
  get(path: string, options?: object): Promise<any> {
    const url = UrlFactory.create(this.baseUrl, { ...options, path: path });
    const fetchOptions = this._getFetchOptions(HTTPMethod.GET);
    return this._fetch(url, fetchOptions);
  }

  /**
   * @param {string} path
   * @param {object} postParams
   *
   * @returns {Promise}
   */
  postAsJson(path: string, postParams: object = {}): Promise<any> {
    const url = UrlFactory.create(this.baseUrl, { path: path });
    const fetchOptions = this._getFetchOptions(HTTPMethod.POST);
    // @ts-ignore
    fetchOptions.body = JSON.stringify(postParams);
    fetchOptions.headers['Content-Type'] = 'application/json';

    return this._fetch(url, fetchOptions);
  }

  /**
   * @param {string} method - GET, POST, etc
   */
  _getFetchOptions(method: HTTPMethod) {
    return {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json, text/plain, */*',
      },
      method,
    };
  }

  async _fetch(url, options) {
    const response = await fetch(url, options);
    if (!response.ok) {
      return response.json().then((json) => {
        const error = new Error(json.message);
        // @ts-ignore
        error.json = json;

        throw error;
      });
    } else if (response.headers.get('Content-Type') === 'application/pdf') {
      return this._openBlob(response);
    } else {
      return response.json();
    }
  }

  _openBlob(response: Response) {
    return response
      .blob()
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => {
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
      });
  }

  /**
   * @TODO: implement postAsForm, put, delete, etc
   */
}

export default RestService;
