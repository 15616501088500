import React, { KeyboardEventHandler, useState } from 'react';
import { OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import ModalDialog from '@videoblocks/shared-components/dist/mjs/components/Shared/ModalDialog';
import { Button } from '@videoblocks/storywind';

import TeamAPI from '../Member/Team/TeamAPI';

interface InviteTeammateModalProps {
  showModal: boolean;
  onClose: () => void;
}
interface Option {
  label: string;
  value: string;
}

const InviteTeammateModal = (props: InviteTeammateModalProps) => {
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const components = {
    DropdownIndicator: null,
  };

  // ReactSelect code modified from https://react-select.com/creatable
  const createOption = (label: string): Option[] => {
    label = label.trim();
    if (label.includes(' ')) {
      return label.split(' ').map((e) => ({ label: e, value: e }));
    }
    if (label.includes(',')) {
      return label.split(',').map((e) => ({ label: e, value: e }));
    }
    return [{ label, value: label }];
  };

  const onBlur = () => {
    if (currentEmail != '') {
      setEmails([...emails, ...createOption(currentEmail)]);
      setCurrentEmail('');
    }
  };

  const handleInputChange = (inputValue: string) => {
    setCurrentEmail(inputValue);
  };

  const handleChange = (value: OnChangeValue<Option, true>) => {
    setEmails([...value]);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!currentEmail) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
      case ',':
        // If any of these keys are pressed, create a pill for the email and
        // start a new one
        setEmails([...emails, ...createOption(currentEmail)]);
        setCurrentEmail('');
        event.preventDefault();
    }
  };
  const isEmailValid = (email) => {
    const mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!email.match(mailformat);
  };

  const handleSendInvitations = async () => {
    const invalidEmails = emails.filter((e) => !isEmailValid(e.value));
    if (invalidEmails.length === 0) {
      const teamAPI = new TeamAPI();
      setInvalidEmails([]);

      emails.forEach((e) =>
        teamAPI.userCreate({ email: e.value, isTrialUser: true })
      );
      setShowSuccessScreen(true);
      setEmails([]);
    } else {
      setInvalidEmails(invalidEmails.map((ie) => ie.value));
    }
  };

  const customStyles = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        border: invalidEmails.includes(data.value) ? '2px #b91c1c solid' : '',
      };
    },
  };

  const onCloseModal = () => {
    setShowSuccessScreen(false);
    setEmails([]);
    setInvalidEmails([]);
    props.onClose();
  };

  return (
    <ModalDialog
      contentLabel={
        showSuccessScreen
          ? 'Your invitation(s) have been sent!'
          : 'Invite your team'
      }
      className="text-center max-w-lg md:max-w-xl px-8 overflow-auto max-h-9/10"
      isOpen={props.showModal}
      onRequestClose={onCloseModal}
    >
      {showSuccessScreen ? (
        <div className="flex-col">
          <p>You're seconds away from accessing endless creative assets!</p>
          <div className="mt-6">
            <Button fullWidth={true} onClick={onCloseModal}>
              Continue to Storyblocks
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex-col -mt-4 md:mt-0">
          <p>Invite your team and access endless creative assets!</p>
          <div className="flex-col text-left">
            <label>
              Email addresses
              <CreatableSelect
                components={components}
                inputValue={currentEmail}
                isClearable
                isMulti
                menuIsOpen={false}
                onBlur={onBlur}
                onInputChange={handleInputChange}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Separate emails with space, comma, tab, or enter"
                value={emails}
                styles={customStyles}
              />
            </label>
            {invalidEmails.length > 0 && (
              <p className="text-red-700 mt-1">
                One or more email addresses you entered are invalid. Please
                check all entered emails.
              </p>
            )}
          </div>
          <div className="mt-6">
            <Button fullWidth={true} onClick={handleSendInvitations}>
              Send Invitation(s)
            </Button>
          </div>
          <div className="mt-6">
            <a
              role="button"
              tabIndex={0}
              className="cursor-pointer"
              onClick={onCloseModal}
              onKeyDown={onCloseModal}
            >
              Skip this step
            </a>
          </div>
        </div>
      )}
    </ModalDialog>
  );
};

export default InviteTeammateModal;
