import React, { useEffect, useState } from 'react';

import { Button } from '@videoblocks/storywind';

import InviteTeammateModal from './InviteTeammateModal';

type EntTrialBannerProps = {
  daysLeftInTrial: number;
  opportunityOwnerEmail: string;
};

const EntTrialBanner = (props: EntTrialBannerProps) => {
  const { daysLeftInTrial, opportunityOwnerEmail } = props;
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isTrialUserFirstLogIn = urlParams.get('isTrialUserFirstLogIn');
    if (isTrialUserFirstLogIn) {
      setIsInviteModalOpen(true);
    }
  }, []);

  return (
    <div className="bg-blue-1000 h-24 md:h-16 flex items-center justify-center md:justify-end">
      <div className="flex flex-col justify-between lg:w-3/5 md:flex-row">
        <div className="flex flex-col text-white items-center">
          {daysLeftInTrial > 0
            ? `${daysLeftInTrial} days left in your Enterprise Trial`
            : 'Your Enterprise Trial has expired'}
          Have any questions? Contact{' '}
          <a className="text-white" href={`mailto:${opportunityOwnerEmail}`}>
            {opportunityOwnerEmail}
          </a>
        </div>
        <div className="flex justify-center lg:mr-10 md:mr-6 md:ml-4">
          <Button onClick={() => setIsInviteModalOpen(true)}>
            Invite Teammates
          </Button>
        </div>
      </div>
      <InviteTeammateModal
        showModal={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </div>
  );
};

export default EntTrialBanner;
