import { TopLevelCategories } from '@videoblocks/shared-components/dist/mjs/types/types';

import { ContentClass } from '../app/Shared/enums';

export type NavCategory = {
  url: string;
  title: string;
  telemetry?: string;
  isNew?: boolean;
};

export interface MenuItem {
  url: string;
  target?: string;
  title: string;
}

export interface MenuList {
  [key: string]: MenuItem;
}

export type PlanCta = {
  id: string;
  url: string;
  linkText: string;
  mobileLinkText: string;
};

export enum OtherCategoryTypes {
  CREATIVE_TOOLS = 'creative-tools',
  BUSINESS = 'business',
  RESOURCES = 'resources',
}

export interface UiReducerState {
  nav: {
    menu: {
      collections: Record<ContentClass, NavCategory[]>;
      top_level_categories: TopLevelCategories;
      account: MenuList;
      showFavorites: boolean;
      isOpen: boolean;
      planCta: PlanCta;
      organizationName: string;
      shouldShowEnterpriseExperience: boolean;
      remainingExports: number;
      starterPlanRemainingDownloads: number;
    };
  };
}
