/**
 * Defers the given task to run after the next frame is rendered.
 * This helper allows us to punt potentially taxing logic that might otherwise
 * cause the current frame to get delayed.
 *
 * @see https://web.dev/articles/optimize-inp#yield_to_allow_rendering_work_to_occur_sooner
 * @param callback
 */
export default function deferToNextFrame(callback: () => void): void {
  requestAnimationFrame(() => {
    setTimeout(callback, 0);
  });
}
