import { Telemetry } from '@videoblocks/kafka-rest-client';
import { SearchFilterContentTypes } from '@videoblocks/shared-components';
import { fetchTypeahead as sharedFetchTypeahead } from '@videoblocks/shared-components/dist/mjs/components/Shared/SearchUtils';

import { SelectedSearchFilterOptions } from '../containers/MenuContainerInterfaces';
import SearchFilterOptions from '../entities/SearchFilterOptions';

export function fetchTypeahead(
  contentType: SearchFilterContentTypes,
  query = '',
  typeaheadEnhancementExperiment = false
) {
  const typeAheadTimer = Telemetry.timer('search.frontEnd.typeAhead');
  return sharedFetchTypeahead(
    contentType,
    query,
    typeaheadEnhancementExperiment,
    typeAheadTimer
  );
}

export const searchTypeToRouteMap = {
  footage: 'video',
  'motion-backgrounds': 'motion-graphics',
  templates: 'templates',
};

export function contentTypeToUrlType(
  contentType: SearchFilterContentTypes | string
) {
  let typeUrlPart;
  switch (contentType) {
    case SearchFilterContentTypes.All_videos_content_type:
    case SearchFilterContentTypes.All_audio_content_type:
    case SearchFilterContentTypes.All_images_content_type:
      typeUrlPart = '';
      break;
    case 'motionbackgrounds':
      typeUrlPart = 'motion-backgrounds';
      break;
    case 'sfx':
      typeUrlPart = 'sound-effects';
      break;
    default:
      typeUrlPart = contentType;
  }
  return typeUrlPart;
}

export function truncateString(word, maxLength = 20) {
  if (word.length > maxLength) {
    return word.slice(0, maxLength) + '...';
  }

  return word;
}

export const isEncoded = (string = '') => {
  try {
    // if string is not encoded or cannot be decoded then it will throw an exception
    decodeURIComponent(string);
    return true;
  } catch (e) {
    return false;
  }
};

// This fixes a bug in v5 of react-router where "%" is not encoded properly.
// Upgrading to v6.22.1 should fix this in the future.
// https://github.com/remix-run/react-router/issues/10814
// TODO: can be removed when updated to v6

export const encodeSearchTerm = (searchTerm = '') => {
  return isEncoded(searchTerm) ? searchTerm : encodeURIComponent(searchTerm);
};

export const getDecodedSearchTerm = (searchTerm = '') => {
  return decodeURIComponent(encodeSearchTerm(searchTerm));
};

export const calculateAppliedFilterNums = (
  selectedSearchFilterOptions: SelectedSearchFilterOptions,
  contentTypeToKeysMap: Record<string, string[]>,
  defaultSearchOptions: SearchFilterOptions
) => {
  let total = 0;

  const keys = contentTypeToKeysMap[selectedSearchFilterOptions.contentType];

  let isMinDurationDifferent = false;
  let isTempoMinDifferent = false;

  keys.forEach((key) => {
    const defaultValue = defaultSearchOptions[key];
    const selectedValue = selectedSearchFilterOptions[key];

    const isDefaultFalsyOrEmptyArray =
      !defaultValue ||
      (Array.isArray(defaultValue) && defaultValue.length === 0);
    const isSelectedFalsyOrEmptyArray =
      !selectedValue ||
      (Array.isArray(selectedValue) && selectedValue.length === 0);

    // spacial case for usageRights
    if (key === 'usageRights' && selectedValue === 'all') {
      return;
    }

    if (isDefaultFalsyOrEmptyArray && isSelectedFalsyOrEmptyArray) {
      // Treat as the same
      return;
    }

    if (defaultValue !== selectedValue) {
      if (key === 'minDuration') {
        isMinDurationDifferent = true;
      }

      if (key === 'maxDuration' && isMinDurationDifferent) {
        return;
      }

      if (key === 'tempoMin') {
        isTempoMinDifferent = true;
      }

      if (key === 'tempoMax' && isTempoMinDifferent) {
        return;
      }

      if (Array.isArray(selectedValue)) {
        total += selectedValue.length;
      } else {
        total += 1;
      }
    }
  });

  return total;
};

const CATEGORIES_FILTER_KEY = 'categories';

const sharedVideoFilterKeys = [
  'contentType',
  'videoQuality',
  'talentReleased',
  'propertyReleased',
  'minDuration',
  'maxDuration',
  'frameRates',
  'usageRights',
];

export const videoSearchContentTypeToKeys = {
  'all-videos': sharedVideoFilterKeys,
  footage: [CATEGORIES_FILTER_KEY, ...sharedVideoFilterKeys],
  'motion-backgrounds': [CATEGORIES_FILTER_KEY, ...sharedVideoFilterKeys],
  templates: [CATEGORIES_FILTER_KEY, ...sharedVideoFilterKeys],
};

export const audioSearchContentTypeToKeys = {
  'all-audio': ['contentType', 'minDuration', 'maxDuration'],
  music: [
    'contentType',
    'musicMoods',
    'musicGenres',
    'musicInstruments',
    'vocalType',
    'tempoMin',
    'tempoMax',
    'minDuration',
    'maxDuration',
  ],
  sfx: [
    CATEGORIES_FILTER_KEY,
    'contentType',
    'sfxCategories',
    'minDuration',
    'maxDuration',
  ],
};

const sharedImageFilterKeys = [
  'contentType',
  'hasTransparency',
  'orientation',
  'color',
  'talentReleased',
  'propertyReleased',
  'usageRights',
];

export const imageSearchContentTypeToKeys = {
  'all-images': sharedImageFilterKeys,
  photos: [CATEGORIES_FILTER_KEY, ...sharedImageFilterKeys],
  snapshots: [CATEGORIES_FILTER_KEY, ...sharedImageFilterKeys],
  vectors: [CATEGORIES_FILTER_KEY, ...sharedImageFilterKeys],
  illustrations: [CATEGORIES_FILTER_KEY, ...sharedImageFilterKeys],
};
