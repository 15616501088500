export const mobileNavTopLevelCategories = [
  'stock-footage',
  'animated-backgrounds',
  'royalty-free-music',
  'sound-effects',
  'Templates',
  'Images',
  'Collections',
];

export const mobileMiddleMenu = [
  ...mobileNavTopLevelCategories,
  'enterprise',
  'account',
];
export const mobileRightMenu = mobileMiddleMenu.filter(
  (category) => category !== 'royalty-free-music'
);
