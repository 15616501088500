class UrlFactory {
  /**
   *
   * Mimics logic in MvcRouter::MakeRoute
   *
   * @param {string} url - URL that can contain parameters that need to be injected via urlParams, e.g., https://www.videoblocks.com/api/member/{memberId}
   * @param {object} [options]
   * @param {string} [options.path] - a path to add to end of URL, e.g., /member/create
   * @param {object} [options.urlParams] - keys are the placeholder in the URL, values are the value to inject
   * @param {object} [options.queryParams] - standard query params to append to the URL after ?; note, any existing query param values will be overwritten
   * @returns {string} - full URL
   */
  static create(url, options) {
    url = url || '';
    options = options || {};
    const validOptions = ['path', 'urlParams', 'queryParams'];
    Object.keys(options).forEach((key) => {
      if (!validOptions.includes(key)) {
        throw new Error(`Invalid key provided for options: ${key}`);
      }
    });

    const trimmedUrl = url.replace(/\/$/, '');
    const urlWithPathJoined = UrlFactory._join(trimmedUrl, options.path);
    const urlWithParamPlaceholdersFilled = UrlFactory._injectUrlParams(
      urlWithPathJoined,
      options.urlParams
    );
    const urlWithQueryParams = UrlFactory._addQueryParams(
      urlWithParamPlaceholdersFilled,
      options.queryParams
    );

    return urlWithQueryParams;
  }

  /**
   * @param {string} url
   * @param {string|null|undefined} path
   * @returns {string}
   * @private
   */
  static _join(url, path) {
    if (!path) {
      return url;
    }

    const trimmedPath = path.replace(/^\//, '').replace(/\/$/, '');
    return `${url}/${trimmedPath}`;
  }

  /**
   * @param {string} url
   * @param {object} urlParams
   * @returns {string}
   * @private
   */
  static _injectUrlParams(url, urlParams) {
    if (!urlParams) {
      return url;
    }

    const keys = Object.keys(urlParams);

    if (keys.length === 0) {
      return url;
    }

    let urlWithInjectedParams = url;

    keys.forEach((key) => {
      const keyRegex = new RegExp(`{${key}}`);
      const match = urlWithInjectedParams.match(keyRegex);

      if (!match) {
        throw new Error(
          'Cannot create route - invalid key passed in urlParams'
        );
      }

      urlWithInjectedParams = urlWithInjectedParams.replace(
        keyRegex,
        urlParams[key]
      );
    });

    return urlWithInjectedParams;
  }

  /**
   * @param {string} url
   * @param {object} queryParams
   * @returns {string}
   * @private
   */
  static _addQueryParams(url, queryParams) {
    if (!queryParams) {
      return url;
    }

    const parsedUrl = new URL(url);
    Object.entries(queryParams).forEach(([key, value]) => {
      parsedUrl.searchParams.set(key, value ?? '');
    });

    return parsedUrl.toString();
  }
}

export default UrlFactory;
