import SiteConstants from '../SiteConstants/SiteConstants';
import RestService from './RestService';
import UrlFactory from './UrlFactory';

class RestServiceFactory {
  /**
   * @param {string} [path='']
   * @returns {RestService}
   */
  static fromCurrentHost(path: string = ''): RestService {
    const shortSite = SiteConstants.getInstance().getAbbreviatedSiteName();

    const baseUrlWithoutPath =
      window && window.location
        ? window.location.protocol + '//' + window.location.host
        : 'https://' + shortSite + '.local';
    const baseUrlWithPath = UrlFactory.create(baseUrlWithoutPath, {
      path: path,
    });

    return new RestService(baseUrlWithPath);
  }
}

export default RestServiceFactory;
